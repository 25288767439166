import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Select, Alert, Row, Col, Divider } from "antd";
import { useRef } from "react";
import { URL_API_VALUES, URL_DEVICE_QUERY } from "../../../Utils/constant";
import Loading from "../../Loading";
import {
  QuestionOutlined
} from "@ant-design/icons";

export default function ModalQuery(props) {
  const { isOpen, close, query, device } = props;
  const [select, setSelect] = useState(false);
  const [readonly, setReadOnly] = useState(false);
  const [options, setOptions] = useState([]);
  const [firstValue, setFirstValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);

  const frm = useRef(null);
  const { Option } = Select;
  const { TextArea } = Input;

  const onClose = () => {
    frm.current.resetFields();
    close();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (values) => {
    setLoading(true);
    frm.current.setFieldsValue({
      value: values.value,
      result: "",
    });

    let obj = {
      ChipId: query.ClientId.replace("IHCT_", ""),
      QueryNumber: query.QueryId,
      Value: values.value,
    };

    ExceuteQuery(obj)
      .then((json) => {
        frm.current.setFieldsValue({
          value: values.value,
          result: JSON.stringify(json),
        });

        setResult(json);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  function ExceuteQuery(obj) {
    return fetch(`${URL_DEVICE_QUERY}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(obj),
    }).then((response) => response.json());
  }

  useEffect(() => {
    if (!isOpen) {
      setResult(false);
      return;
    }

    if (query && frm && device) {
      fetch(
        `${URL_API_VALUES}/query?classname=${device.ClassName}&queryid=${query.QueryId}&format=json`
      )
        .then((response) => response.json())
        .then((data) => {
          frm.current.resetFields();
          if (data.Values.length > 1) {
            frm.current.setFieldsValue({ value: data.Values[0].Value });
            setOptions(data.Values);
            setFirstValue(data.Values[0].Value);
            setSelect(true);
          } else if (data.Values.length) {
            if (typeof data.Values[0].Value) {
              setReadOnly(true);
              frm.current.setFieldsValue({ value: data.Values[0].Name });
            } else {
              setReadOnly(false);
            }
            setSelect(false);
          } else {
            setReadOnly(false);
            setSelect(false);
          }
        });
    }
  }, [query, frm, isOpen]);

  const Result = result ? (
    <>
      <Row>
        <Divider></Divider>
        {result.Code === 0 ? (
          <>
            <Alert
              message="Request Success"
              type="success"
              style={{ width: "100%" }}
            />
            <Form.Item style={{ marginTop: 10, width: "100%" }} label="Result">
              <Input value={result.Value} readOnly />
            </Form.Item>
          </>
        ) : (
          <Alert
            message={"Request Invalid: " + result.Description}
            type="error"
            style={{ width: "100%" }}
          />
        )}
      </Row>
    </>
  ) : null;

  if (!query) return "";

  return (
    <Modal
      className="device-modal-edit"
      title={<Col><QuestionOutlined /> <span><b>   Execute an Query ...</b></span></Col> }  
      visible={isOpen}
      onCancel={onClose}
      loading={loading}
      onOk={() => {
        frm.current.submit();
      }}
    >
      <h2>{`${query.QueryId} : ${query.Name}`}</h2>
      <Form
        ref={frm}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        style={{ marginTop: "15px" }}
      >
        <Form.Item
          label="Value"
          name="value"
          rules={[{ required: true, message: "Please input value!" }]}
        >
          {select ? (
            <Select defaultValue={firstValue}>
              {options.map((item) => (
                <Option key={item.Name} value={item.Value}>
                  {item.Name}
                </Option>
              ))}
            </Select>
          ) : (
            <Input placeholder="input a value" readOnly={readonly} />
          )}
        </Form.Item>
      </Form>
      {loading ? <Loading /> : result ? Result : null}
    </Modal>
  );
}
