import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Form, Input, Button, Checkbox } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

import "./ForgotForm.scss";

export default function ForgotForm(props){

    const { setSelectedForm } = props;
    const frm = useRef(null);
  
    const onFinish = (values) => {
      console.log(values);
    };
  
    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };
  
    return (
      <Form
        name="normal_login"
        className="forgot-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        size="large"
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: "Please input your Username!" }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item style={{ textAlign: "center" }}>
          <Button type="primary" htmlType="submit" className="login-form-button">
            Send password reset email
          </Button>
          <a
            className="login-form-register"
            href="#"
            onClick={() => setSelectedForm(null)}
          >
            Cancel
          </a>
        </Form.Item>
      </Form>
    );
}