import React from "react";
import { Link } from "react-router-dom";
import {useHistory} from "react-router-dom";

import { Row, Col, Menu, Popover, Badge, Button, Tooltip } from "antd";
import { UserOutlined, SettingOutlined, BulbOutlined } from "@ant-design/icons";
import Logo from "../../assets/png/logo.png";

import "./TopMenu.scss";

export default function TopMenu(props) {
  let history = useHistory();
  const { user, setReloadApp } = props;
  const alertMenu = <p>no se que poner aqui!</p>;
  /**/

  const SignOut = (
    <Button
      type="primary"
      shape="round"
      onClick={() => {
        localStorage.clear();
        setReloadApp(true);
        history.push("/");
      }}
    >
      Sign Out!
    </Button>
  );

  return (
    <Row className="top-menu">
      <Col flex="150px">
        <Link to="/">
          <img className="logo" src={`${Logo}`}></img>
        </Link>
      </Col>
      <Col flex="auto">
        <div className="top-menu__user">
          <Popover placement="bottomRight" content={alertMenu} trigger="click">
            <Badge count="2">
              <Button shape="circle" icon={<BulbOutlined />} />
            </Badge>
          </Popover>
          <Popover placement="bottomRight" content={alertMenu} trigger="click">
            <Button shape="circle" icon={<SettingOutlined />} />
          </Popover>
          <Popover placement="bottomRight" content={SignOut} trigger="click">
            <Button shape="circle" icon={<UserOutlined />} />
          </Popover>
          <h3>{user.DisplayName}</h3>
        </div>
      </Col>
    </Row>
  );
}
