import React from "react";
import { List, Col, Button, Badge } from "antd";
import {
  QuestionOutlined,
  InteractionOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
import "./ExtendedList.scss";

export default function ExtendedList(props) {
  const { typeList, records, openModal, device } = props;

  return (
    <List
      className="extended-list"
      size="small"
      header={<div>{typeList}</div>}
      footer={<div>Quantity: {records.length}</div>}
      bordered
      dataSource={records}
      renderItem={(item) => (
        <List.Item>
          <ExtendedItem
            item={item}
            typeList={typeList}
            openModal={openModal}
            device={device}
          />
        </List.Item>
      )}
    />
  );
}

export function ExtendedItem(props) {
  const { item, typeList, openModal, device } = props;

  return (
    <>
      <Col span={8}>
        {typeList === "Actions" ? (
          <span>{item.ActionId}</span>
        ) : typeList === "Events" ? (
          <span>{item.EventId}</span>
        ) : (
          <span>{item.QueryId}</span>
        )}
      </Col>
      <Col span={8} style={{ textAlign: "Left" }}>
        <span>{item.Name}</span>
      </Col>
      <Col span={8} style={{ textAlign: "Right" }}>
        {typeList === "Actions" ? (
          <Button
            shape="round"
            icon={<InteractionOutlined />}
            onClick={() => openModal(item, device)}
          />
        ) : typeList === "Events" ? (
          <Badge
            count={item.Count}
            showZero={true}
            overflowCount={9999}
            className="badge-count"
            size="default"
          />
        ) : (
          <Button
            shape="round"
            icon={<QuestionOutlined />}
            onClick={() => openModal(item, device)}
          />
        )}
      </Col>
    </>
  );
}
