import React, { useState, useEffect } from "react";
import Auth from "./pages/Auth";
import LayoutSecure from "./LayoutSecure/LayoutSecure";

export default function App() {
  const [userApp, setUserApp] = useState(null);
  const [reloadApp, setReloadApp] = useState(false);

  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    setReloadApp(false);

    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      setUserApp(foundUser);
    } else {
      setUserApp(null);
    }
  }, [reloadApp]);

  return (
    <>
      {userApp ? (
        <LayoutSecure user={userApp} setReloadApp={setReloadApp} />
      ) : (
        <Auth setUserApp={setUserApp} />
      )}
    </>
  );
}
