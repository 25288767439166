import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, Input, Select } from "antd";
import {SuperIconsSelect} from "../../SuperIcons";
import { URL_API_DEVICES }  from "../../../Utils/constant";

export default function ModalEdit(props) {
  const { isOpen, close, device, refresh, setRefresh } = props;
  const [icon, setIcon] = useState("");

  const frm = useRef(null);
  const onFinish = (values) => {
    UpdateData(values)
      .then((json) => {
        if (json.Code === 200) {
          close();
          setRefresh(!refresh);
        }
      })
      .catch((error) => console.log(error));
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function UpdateData(values) {
    return fetch(`${URL_API_DEVICES}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    }).then((response) => response.json());
  }

  const onChange = (value) => {
    frm.current.setFieldsValue({
      icon: value,
    });
  };

  useEffect(() => {
    if (device) {
      frm.current.resetFields();
      frm.current.setFieldsValue({
        id: device.ChipId,
        icon: device.Icon,
        color: device.Color,
      });
      setIcon(device.Icon);
    }
  });

  return (
    <>
      {device ? (
        <Modal
          className="device-modal-edit"
          title={"Edit device: " + device.Name}
          visible={isOpen}
          onCancel={() => {
            frm.current.resetFields();
            close();
          }}
          autoFocusButton="ok"
          keyboard={true}
          onOk={() => {
            frm.current.submit();
          }}
        >
          <Form
            ref={frm}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item label="Chip" name="id" hidden={true}>
              <Input />
            </Form.Item>

            <Form.Item
              label="Icon"
              name="icon"
              rules={[{ required: true, message: "Please input icon!" }]}
            >
              <Input type="hidden" />
              
              <SuperIconsSelect onChange={onChange} initialValue={icon} />
            </Form.Item>
            <Form.Item
              label="Alias"
              name="color"
              rules={[{ required: true, message: "Please input alias!" }]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      ) : null}
    </>
  );
}
