import React, { useState, useEffect } from "react";
import {
  PageHeader,
  Row,
  Col,
  Input,
  Select,
  notification,
  Button,
  Divider
} from "antd";
import { RetweetOutlined } from "@ant-design/icons";
import Loading from "../../components/Loading";
import DeviceCatalog from "../../components/DeviceCatalog";
import Pagination from "../../components/Pagination";
import { URL_API, SIZE } from "../../Utils/constant";
import "./Home.scss";

export default function Home() {
  const { Search } = Input;
  const { Option } = Select;

  //States.....
  const [loading, setLoading] = useState(true);
  const [devicesList, setDevicesList] = useState(null);
  const [error, setError] = useState([]);

  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("-State");

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const res = await fetch(
          `${URL_API}/devices?search=${search}&sort=${order}&page=${page}&size=${SIZE}&format=json`
        );
        const json = await res.json();
        setDevicesList(json);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    })();
  }, [page, order, search, refresh]);

  const openNotificationWithIcon = () => {
    notification.error({ message: "Error" });
  };

  const onSearch = (value) => setSearch(value);
  const onChangePage = (page) => setPage(page);

  return (
    <div className="home">
      <Row className="header">
        <Col span="24">
          <PageHeader
            className="site-page-header"
            title="IOT Devices"
            extra={[
              <Search
                placeholder="Search ..."
                allowClear
                size="large"
                onSearch={onSearch}
              />,
            ]}
          />
        </Col>
        <Col span={19}></Col>
        <Col span={5} style={{ textAlign: "right", paddingRight: "20px" }}>
          <p style={{ display: "inline", marginRight: "10px" }}>Order by </p>
          <Select
            defaultValue="State"
            style={{ marginRight: "20px" }}
            onChange={(e) => setOrder(e)}
          >
            <Option value="-State">State</Option>
            <Option value="Name">Name</Option>
            <Option value="ChipId">ChipId</Option>
          </Select>
          <Button
            type="primary"
            shape="round"
            loading={loading}
            icon={<RetweetOutlined />}
            onClick={() => setRefresh(!refresh)}
          >
            Refresh
          </Button>
        </Col>
      </Row>
      <Divider style={{ marginTop: 20 }} />
      <Row>
        {!devicesList ? (
          <Col span="24">
            <Loading />
          </Col>
        ) : (
          <Col span="24">
            <Row>
              <DeviceCatalog
                devices={devicesList}
                setRefresh={setRefresh}
                refresh={refresh}
              ></DeviceCatalog>
            </Row>
            <Pagination
              currentPage={devicesList.Page}
              totalItems={devicesList.TotalResults}
              pageSize={devicesList.Count}
              onChangePage={onChangePage}
            />
          </Col>
        )}
      </Row>
    </div>
  );
}
