import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useParams, Redirect, Link } from "react-router-dom";
import moment from "moment";
import {
  URL_REFRESH_DEVICE,
  URL_API_DEVICES,
  URL_DEVICE_ACTION,
} from "../../Utils/constant";
import {
  PageHeader,
  Row,
  Col,
  Descriptions,
  Badge,
  Button,
  Divider,
  notification,
  Breadcrumb,
  Modal,
} from "antd";
import {
  RetweetOutlined,
  AreaChartOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import ExtendedList from "../../components/ExtendedList";
import ModalAction from "../../components/Modals/ModalAction";
import ModalQuery from "../../components/Modals/ModalQuery";
import ModalEvent from "../../components/Modals/ModalEvent";
import Loading from "../../components/Loading";
import "./device.scss";

export default function Device(props) {
  //States
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingSync, setLoadingSync] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isVisibleMAction, setisVisibleMAction] = useState(false);
  const [isVisibleMEvent, setisVisibleMEvent] = useState(false);
  const [isVisibleMQuery, setisVisibleMQuery] = useState(false);
  const [action, setAction] = useState(null);
  const [device, setDevice] = useState(null);
  const [query, setQuery] = useState(null);

  const { id } = useParams();
  const { confirm } = Modal;
  const history = useHistory();
  const goDevice = useCallback(() => history.push(`/dashboard/${id}`), [
    history,
  ]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await fetch(
        `${URL_API_DEVICES}/${id}?format=json`
      );
      const object = await response.json();
      setResult(object);
      console.log(object);
      setLoading(false);
    })();
  }, [refresh]);

  function RefreshDevice(id) {
    return fetch(`${URL_REFRESH_DEVICE}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ChipId: id }),
    }).then((response) => response.json());
  }

  function ExceuteAction(obj) {
    return fetch(`${URL_DEVICE_ACTION}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(obj),
    }).then((response) => response.json());
  }

  const handleRefresh = (id) => {
    setLoadingSync(true);
    RefreshDevice(id)
      .then((json) => {
        json.Code === 0
          ? openNotificationWithIcon(
              "success",
              "Send Command",
              "Refresh command was sent to the device..."
            )
          : openNotificationWithIcon(
              "error",
              "Send Command fail",
              json.Description
            );
        setLoadingSync(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingSync(false);
      });
  };

  const openAction = (action, device) => {
    setAction(action);
    setDevice(device);
    setisVisibleMAction(true);
  };

  const closeAction = () => setisVisibleMAction(false);

  const executeAction = (chipid, idAction, value) => {
    setLoading(true);
    ExceuteAction({ chipId: chipid, ActionNumber: idAction, Value: value })
      .then((json) => {
        if (json.Code === 0) {
          openNotificationWithIcon(
            "success",
            "Sent Action",
            `${json.MetaData.Action.Name} Executed OK!`
          );
          setLoading(false);
        } else {
          openNotificationWithIcon(
            "error",
            "Sent Action Fail...",
            json.Description
          );
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const openEvent = () => setisVisibleMEvent(true);

  const closeEvent = () => setisVisibleMEvent(false);

  const openQuery = (query, device) => {
    setQuery(query);
    setDevice(device);
    setisVisibleMQuery(true);
  };

  const closeQuery = () => setisVisibleMQuery(false);

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
      placement: "topRight",
    });
  };

  return (
    <Row className="device">
      <ModalAction
        isOpen={isVisibleMAction}
        action={action}
        device={device}
        close={closeAction}
        exec={executeAction}
      />
      <ModalEvent isOpen={isVisibleMEvent} close={closeEvent} />
      <ModalQuery
        isOpen={isVisibleMQuery}
        device={device}
        query={query}
        close={closeQuery}
      />
      <Col span={3} style={{ marginTop: 30, marginLeft: 30 }}>
        <Breadcrumb>
          <Breadcrumb.Item key="home">
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item key="device">
            {result ? result.Device.ClientId : ""}
          </Breadcrumb.Item>
        </Breadcrumb>
      </Col>
      <Col span="24" style={{ marginTop: 15 }}>
        {result ? (
          !result.hasOwnProperty("ResponseStatus") ? (
            <>
              <PageHeader
                className="site-page-header"
                style={{ backgroundColor: "white" }}
                title={result.Device.Name}
                subTitle={result.Device.ClassName}
                extra={[
                  <Badge
                    color={result.Device.State === "Online" ? "Green" : "Red"}
                    dot
                  >
                    <b
                      style={{
                        color:
                          result.Device.State === "Online" ? "Green" : "Red",
                      }}
                    >
                      {result.Device.State}
                    </b>
                  </Badge>,
                  <Button
                    onClick={goDevice}
                    renderAs="Button"
                    key="1"
                    shape="round"
                    icon={<AreaChartOutlined />}
                  >
                    DashBoard
                  </Button>,
                  <Button
                    key="1"
                    icon={<RetweetOutlined />}
                    shape="round"
                    loading={loadingSync}
                    onClick={() =>
                      confirm({
                        icon: <ExclamationCircleOutlined />,
                        content: "Do you want to re-sync the device?",
                        onOk() {
                          handleRefresh(result.Device.ChipId);
                        },
                      })
                    }
                    style={{ marginLeft: "20px" }}
                  >
                    Sync Device
                  </Button>,
                  <Button
                    key="1"
                    icon={<RetweetOutlined />}
                    shape="round"
                    type="primary"
                    loading={loading}
                    onClick={() => setRefresh(!refresh)}
                    style={{ marginLeft: "20px" }}
                  >
                    Refresh
                  </Button>,
                ]}
              >
                <Divider style={{ marginTop: 0 }} />
                <Descriptions>
                  <Descriptions.Item label="Chip Id">
                    <span style={{ fontWeight: "bold" }}>
                      {result.Device.ChipId}
                    </span>
                  </Descriptions.Item>
                  <Descriptions.Item label="Last Ip">
                    <span style={{ fontWeight: "bold" }}>
                      {result.Device.LastIp}
                    </span>
                  </Descriptions.Item>
                  <Descriptions.Item label="Last Access">
                    <span style={{ fontWeight: "bold" }}>
                      {moment(result.Device.LastAccess).format(
                        "DD.MM.YYYY HH:mm:ss"
                      )}
                    </span>
                  </Descriptions.Item>
                  <Descriptions.Item label="Actions">
                    {result.Device.Actions}
                  </Descriptions.Item>
                  <Descriptions.Item label="Events">
                    {result.Device.Events}
                  </Descriptions.Item>
                  <Descriptions.Item label="Queries">
                    {result.Device.Querys}
                  </Descriptions.Item>
                </Descriptions>
              </PageHeader>
              <Divider
                orientation="center"
                style={{ marginTop: 20, marginBottom: 20 }}
              >
                Actions, Events and Queries
              </Divider>
              <Row style={{ textAlign: "center" }}>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                  style={{ paddingRight: "20px", paddingLeft: "20px" }}
                >
                  <ExtendedList
                    records={result.Actions}
                    device={result.Device}
                    typeList="Actions"
                    openModal={openAction}
                  ></ExtendedList>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                  style={{ paddingRight: "20px", paddingLeft: "20px" }}
                >
                  <ExtendedList
                    records={result.Events}
                    device={result.Device}
                    typeList="Events"
                    openModal={openEvent}
                  ></ExtendedList>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                  style={{ paddingRight: "20px", paddingLeft: "20px" }}
                >
                  <ExtendedList
                    records={result.Queries}
                    device={result.Device}
                    typeList="Queries"
                    openModal={openQuery}
                  ></ExtendedList>
                </Col>
              </Row>
            </>
          ) : (
            <Redirect to="/Error404" />
          )
        ) : (
          <Col>
            <Loading />
          </Col>
        )}
      </Col>
    </Row>
  );
}
