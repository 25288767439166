import React from "react";
import {Link} from "react-router-dom";
import {Result, Button} from "antd";
export default function error404() {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Page not Found"
      extra={<Link to="/"> <Button type="primary">Back Home</Button></Link>}
    />
  );
}
