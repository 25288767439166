
// API WEB CONTROLLER
//export const URL_API = "http://10.3.1.20";
//export const URL_API = "http://localhost:53056";
export const URL_API = "http://api.ioter.io";

export const URL_API_DEVICES = `${URL_API}/devices`;
export const URL_API_AUTH = `${URL_API}/auth`;
export const URL_API_VALUES = `${URL_API}/values/`;
export const SIZE = "12";

//API COMUNICATION IOT
//export const URL_EXEC = "http://66.175.108.198:1885";
//export const URL_EXEC = "http://10.3.1.20:1885";
export const URL_EXEC = "http://api.ioter.io:1885";

export const URL_DEVICE = `${URL_EXEC}/device`;

export const URL_REFRESH_DEVICE = `${URL_DEVICE}/refresh`;
export const URL_DEVICE_ACTION = `${URL_DEVICE}/action`;
export const URL_DEVICE_QUERY = `${URL_DEVICE}/query`;

