import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Form, Input, Button, Checkbox } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import "./RegisterForm.scss";

export default function RegisterForm(props){
    const { setSelectedForm } = props;
    const frm = useRef(null);
  
    const onFinish = (values) => {
      console.log(values);
    };
  
    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };
  
    return (
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        size="large"
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: "Please input your Username!" }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
          />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[{ required: true, message: "Please input your Email!" }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please input your Password!" }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        
        <Form.Item style={{ textAlign: "center" }}>
          <Button type="primary" htmlType="submit" className="login-form-button">
            Sign in
          </Button>
          <a
            className="login-form-register"
            href="#"
            onClick={() => setSelectedForm(null)}
          >
            Already have an account? Sign in.
          </a>
        </Form.Item>
      </Form>
    );
  }
  