import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Form, Input, Button, Checkbox } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { URL_API_AUTH } from "../../../Utils/constant";
import "./LoginForm.scss";

export default function LoginForm(props) {
  const { setSelectedForm, setUserApp } = props;
  const frm = useRef(null);

  const onFinish = (values) => {
    checkUserPass(values)
      .then((json) => {
        if(json.User)
        {
          setUserApp(json.User);
          localStorage.setItem('user', JSON.stringify(json.User))
        }
      })
      .catch((error) => console.log(error));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function checkUserPass(values) {
    return fetch(`${URL_API_AUTH}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    }).then((response) => response.json());
  }

  return (
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      size="large"
    >
      <Form.Item
        name="email"
        rules={[{ required: true, message: "Please input your Email!" }]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Email"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: "Please input your Password!" }]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item>
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>
            <span style={{ color: "white" }}> Remember me</span>
          </Checkbox>
        </Form.Item>

        <a
          className="login-form-forgot"
          href="#"
          onClick={() => setSelectedForm("forgot")}
        >
          Forgot password
        </a>
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Button type="primary" htmlType="submit" className="login-form-button">
          Log in
        </Button>
        <a
          className="login-form-register"
          href="#"
          onClick={() => setSelectedForm("register")}
        >
          register now!
        </a>
      </Form.Item>
    </Form>
  );
}
