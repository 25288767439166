import React from "react";
import { Layout } from "antd";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

//PAGES
import Home from "../pages/Home";
import Error404 from "../pages/error404";
import Device from "../pages/device";
import DeviceChart from "../pages/deviceChart";
import TopMenu from "../components/TopMenu";

//import "./LayoutSecure.scss";

export default function LayoutSecure(props) {
  const { user, setReloadApp } = props;
  const { Header, Content, Footer } = Layout;

  return (
    <div className="layout-secure" style={{height: window.innerHeight}}>
    <Router>
      <Layout>
        <Header>
          <TopMenu user={user} setReloadApp={setReloadApp}/>
        </Header>
        <Content>
          <Switch>
            <Route exact={true} path="/">
              <Home></Home>
            </Route>
            <Route path="/device/:id" exact={true}>
              <Device></Device>
            </Route>
            <Route path="/dashboard/:id" exact={true}>
              <DeviceChart></DeviceChart>
            </Route>
            <Route path="*">
              <Error404></Error404>
            </Route>
          </Switch>
        </Content>
        <Footer className="footer"><p>ihct.mx 2020</p></Footer>
      </Layout>
    </Router>
    </div>
  );
}
