import React from "react";
import Pagination from "rc-pagination";

import "./Pagination.scss";

export default function PagiantionDevice(props) {
  const { currentPage, totalItems, onChangePage, pageSize } = props;

  return(
      <Pagination
        className ="pagination"
        current = {currentPage}
        total = {totalItems}
        pageSize = {pageSize}
        onChange = {onChangePage}
        />
  );
}
