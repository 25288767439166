import React, { useState } from "react";
import { Col, Row } from "antd";
import ForgotForm from "../../components/Auth/ForgotForm";
import RegisterForm from "../../components/Auth/RegisterForm";
import LoginForm from "../../components/Auth/LoginForm";
import BackgroundAuth from "../../assets/jpg/Back2.jpg";
import LogoNameWhite from "../../assets/png/logo.png";

import "./Auth.scss";

export default function Auth(props) {
  const {setUserApp} = props;
  const [selectedForm, setSelectedForm] = useState(null);


  const handlerForm = () => {
    switch (selectedForm) {
      case "forgot":
        return <ForgotForm setSelectedForm={setSelectedForm} />;
      case "register":
        return <RegisterForm setSelectedForm={setSelectedForm} />;
      default:
        return <LoginForm setSelectedForm={setSelectedForm} setUserApp={setUserApp} />;
    }
  };

  return (
    <div className="auth" style={{ backgroundImage: `url(${BackgroundAuth})` }}>
      <div className="auth__dark" />
      
          <div className="auth__box">
            <div className="auth__box-logo">
              <img
                src={LogoNameWhite}
                alt="Inhouse"
              />
            </div>
            {handlerForm()}
          </div>

    </div>
  );
}
