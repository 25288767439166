import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Select } from "antd";

export default function ModalEvent(props) {
  const { isOpen, close, idEvent } = props;

  return (
    <Modal
      className="device-modal-edit"
      title={"Event: " + idEvent}
      visible={isOpen}
      onCancel={close}
    >
      <p>Some contents...</p>
      <p>Some contents...</p>
      <p>Some contents...</p>
    </Modal>
  );
}
