import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, Input, Select, Col } from "antd";
import { URL_API_VALUES } from "../../../Utils/constant";
import {
  QuestionOutlined,
  InteractionOutlined,
  BarChartOutlined,
} from "@ant-design/icons";

export default function ModalAction(props) {
  const { isOpen, close, action, exec, device } = props;
  const [select, setSelect] = useState(false);
  const [readonly, setReadOnly] = useState(false);
  const [options, setOptions] = useState([]);
  const [firstValue, setFirstValue] = useState(0);

  const frm = useRef(null);
  const { Option } = Select;

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (values) => {
    exec(action.ClientId.replace("IHCT_", ""), action.ActionId, values.value);
    close();
  };

  useEffect(() => {
    if (action && frm && device) {
      fetch(
        `${URL_API_VALUES}/action?classname=${device.ClassName}&actionid=${action.ActionId}&format=json`
      )
        .then((response) => response.json())
        .then((data) => {
          frm.current.resetFields();
          if (data.Values.length > 1) {
            frm.current.setFieldsValue({
              value: data.Values[0].Value,
            });
            setFirstValue(data.Values[0].Value);
            setSelect(true);
            setOptions(data.Values);
          } else if (data.Values.length===1) {
            if (typeof data.Values[0].Value) {
              setReadOnly(true);
              frm.current.setFieldsValue({
                value: data.Values[0].Name,
              });
            } else {
              setReadOnly(false);
            }
            setSelect(false);
          } else {
            setReadOnly(false);
            setSelect(false);
          }
        });
    }
  }, [action, frm]);

  if (!action) return "";

  return (
    <Modal
      className="device-modal-edit"
      title={<Col><InteractionOutlined /> <span><b>   Execute an Action ...</b></span></Col> }  
      visible={isOpen}
      onCancel={close}
      onOk={() => {
        frm.current.submit();
      }}
    >
      <h2>{`${action.ActionId} : ${action.Name}`}</h2>
      <Form
        ref={frm}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        style={{ marginTop: "15px" }}
      >
        <Form.Item
          label="Value"
          name="value"
          rules={[{ required: true, message: "Please input value!" }]}
        >
          {select ? (
            <Select defaultValue={firstValue}>
              {options.map((item) => (
                <Option key={item.Name} value={item.Value}>
                  {item.Name}
                </Option>
              ))}
            </Select>
          ) : (
            <Input placeholder="input a value" readOnly={readonly} />
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
}
