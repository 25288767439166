import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, Button, Badge } from "antd";
import {
  UserOutlined,
  SettingOutlined,
  EditOutlined,
  InfoCircleOutlined,
  DashboardOutlined,
} from "@ant-design/icons";

import { Link } from "react-router-dom";
import  ModalEdit  from "../Modals/ModalEdit";
import SuperIcons from "../SuperIcons/SuperIcons";
import moment from "moment";
import "./DeviceCatalog.scss";

export default function DeviceCatalog(props) {
  const {
    devices: { Results },
    refresh,
    setRefresh,
  } = props;

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [device, setDevice] = useState(null);
  const closeModal = () => setIsVisibleModal(false);
  const openModal = (device) => {
    setDevice(device);
    setIsVisibleModal(true);
  };

  let devices = Results.map((device) => (
    <Col
      key={device.ChipId}
      xs={24}
      sm={12}
      md={12}
      lg={8}
      xl={6}
      xxl={4}
      className="device-catalog"
    >
      <DeviceCard device={device} openModal={openModal}></DeviceCard>
    </Col>
  ));
  return (
    <>
      {devices}
      <ModalEdit
        refresh={refresh}
        setRefresh={setRefresh}
        isOpen={isVisibleModal}
        close={closeModal}
        device={device}
      ></ModalEdit>
    </>
  );
}

export function DeviceCard(props) {
  const history = useHistory();
  const goDevice = useCallback(() => history.push(`/device/${device.ChipId}`), [
    history,
  ]);
  const goDashBoard = useCallback(
    () => history.push(`/dashboard/${device.ChipId}`),
    [history]
  );

  const { device, openModal, closeModal } = props;
  const { Meta } = Card;

  function getColor(state) {
    switch (state) {
      case "Offline":
        return "Red";
      case "Online":
        return "green";
      default:
        return "blue";
    }
  }

  return (
    <>
      <Badge.Ribbon text={device.State} color={getColor(device.State)}>
        <Card
          cover={
            <Link to={`/device/${device.ChipId}`}>
              <div>
                <SuperIcons
                  type={device.Icon}
                  style={{ fontSize: "4.5em", color: "#ffffff66" }}
                />
                <h2>{device.ClassName}</h2>
                <h3>{device.ClientId}</h3>
              </div>
            </Link>
          }
          actions={[
            <Button
              onClick={() => {
                openModal(device);
              }}
              type="link"
            >
              <SettingOutlined key="setting" />
            </Button>,
            <Button onClick={goDevice} type="link">
              <InfoCircleOutlined key="info" />
            </Button>,
            <Button onClick={goDashBoard} type="link">
              <DashboardOutlined key="dash" />
            </Button>,
          ]}
        >
          <Meta
            title={device.Name}
            description={
              <>
                <Row style={{ marginTop: "10px" }}>
                  <Col span={10}>
                    <b>Alias:</b>
                  </Col>
                  <Col span={14} style={{ textAlign: "right" }}>
                    {device.Color}
                  </Col>
                </Row>
                <Row style={{ marginTop: "5px" }}>
                  <Col span={10}>
                    <b>IP:</b>
                  </Col>
                  <Col span={14} style={{ textAlign: "right" }}>
                    {device.LastIp}
                  </Col>
                </Row>
                <Row style={{ marginTop: "5px" }}>
                  <Col span={10}>
                    <b>Chip Id:</b>
                  </Col>
                  <Col span={14} style={{ textAlign: "right" }}>
                    {device.ChipId}
                  </Col>
                </Row>
                <Row style={{ marginTop: "5px" }}>
                  <Col span={10}>
                    <b>Last Access:</b>
                  </Col>
                  <Col span={14} style={{ textAlign: "right" }}>
                    {moment(device.LastAccess).format("DD/MM/YYYY HH:mm:ss")}
                  </Col>
                </Row>
              </>
            }
          />
        </Card>
      </Badge.Ribbon>
    </>
  );
}
