import React from "react";
import { Select } from "antd";
import {
  WifiOutlined,
  UserOutlined,
  SettingOutlined,
  AndroidOutlined,
  AppleOutlined,
  WindowsOutlined,
  AlertOutlined,
  BulbOutlined,
  EditOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";

export default function SuperIcons(props) {
  const { type, style } = props;
  let icon;

  switch (type) {
    case "wifi":
      icon = <WifiOutlined style={style} />;
      break;
    case "user":
      icon = <UserOutlined style={style} />;
      break;
    case "settings":
      icon = <SettingOutlined style={style} />;
      break;
    case "android":
      icon = <AndroidOutlined style={style} />;
      break;
    case "apple":
      icon = <AppleOutlined style={style} />;
      break;
    case "windows":
      icon = <WindowsOutlined style={style} />;
      break;
    case "alert":
      icon = <AlertOutlined style={style} />;
      break;
    case "bulb":
      icon = <BulbOutlined style={style} />;
      break;
    case "edit":
      icon = <EditOutlined style={style} />;
      break;
    case "ellipsis":
      icon = <EllipsisOutlined style={style} />;
      break;
    default:
      icon = <UserOutlined style={style} />;
      break;
  }

  return <>{icon}</>;
}

export function SuperIconsSelect(props) {
  const { onChange, initialValue } = props;
  const { Option } = Select;

  return (
    <>

      <Select key="sss" defaultValue={initialValue} onChange={(e) => onChange(e)}>
        <Option key="wifi" value="wifi">
          <WifiOutlined /> Wifi
        </Option>

        <Option key="user" value="user">
          <UserOutlined /> User
        </Option>

        <Option key="settings" value="settings">
          <SettingOutlined /> Settings
        </Option>

        <Option key="android" value="android">
          <AndroidOutlined /> Android
        </Option>

        <Option key="apple" value="apple">
          <AppleOutlined /> Apple
        </Option>

        <Option key="alert" value="alert">
          <AlertOutlined /> Alert
        </Option>

        <Option key="bulb" value="bulb">
          <BulbOutlined /> Bulb
        </Option>

        <Option key="edit" value="edit">
          <EditOutlined /> Edit
        </Option>

        <Option key="ellipsis" value="ellipsis">
          <EllipsisOutlined /> Ellipsis
        </Option>
      </Select>
    </>
  );
}
